<template>
  <b-row>
    <b-col cols="12" md="12" class="w-100">
      <b-button class="float-left" variant="secondary" disabled size="sm" block>
        (wip) Load Accounts
        <font-awesome-icon icon="fa-arrow-rotate-right" size="l" />
      </b-button>

      <b-button
        :to="{ name: 'apps-accounting-receivable-account-list', query: { student: studentId } }"
        class="float-left"
        variant="primary"
        size="sm"
        block
      >
        View Accounts
        <font-awesome-icon icon="fa-arrow-up-right-from-square" size="l" />
      </b-button>
    </b-col>
  </b-row>
  <!--  <b-row v-if='false'>

     <b-col lg="12">
       <p class="text-center" v-if="sessionData.length === 0">
         This student is not enrolled in any sessions.
       </p>
     </b-col>

     <b-col cols="12" lg="6" md="6" sm="12" v-for="(account, index) in accountsData" :key="account.id">
       <router-link :to="{ name: 'apps-ar-session-view', params: { id: account.id } }">
         <b-card no-body class="border border-dark">
           <b-card-body class="d-flex w-100">
             <b-avatar
                 :text="avatarText(account.program ? account.program.name : 'AR')"
                 :variant="`light-primary`"
                 size="45px"
                 class="mr-1"
             ></b-avatar>

             <div class="truncate w-100 text-left">
               <h5 class="font-weight-normal">
                 {{ account.name }}

                 <b-dropdown
                     variant="link"
                     no-caret
                     :right="$store.state.appConfig.isRTL"
                     class="float-right d-inline"
                     style="margin-top:-10px;"
                 >

                   <template #button-content>
                     <feather-icon
                         icon="MoreVerticalIcon"
                         size="16"
                         class="align-middle text-body"
                     />
                   </template>

                   <b-dropdown-item
                       v-if="$can('ar.session.student.remove')"
                       @click="removeFromSession(account)">
                     <feather-icon icon="ArchiveIcon"/>
                     <span class="align-middle ml-50">Remove</span>
                   </b-dropdown-item>
                 </b-dropdown>
               </h5>

               <span class="float-left">
               <b-badge :variant="resolveSessionStatusVariant(account.status)">
                 {{ account.status }}
               </b-badge>
             </span>

               <span class="float-right" v-if="account.startDate">
                 {{ moment(account.startDate).utc().format('MMMM Do YYYY') }}
               </span>
               <span class="float-right" v-else>
                 No Class Scheduled
               </span>


             </div>
           </b-card-body>
         </b-card>
       </router-link>
     </b-col>

     <b-col cols="12" md="12" class="w-100">

       <b-button @click="openSessionSelect()" class="float-left" variant="primary" size="sm">
         Add to Session
       </b-button>

       <b-pagination
           v-model="sessionsPage"
           :per-page="sessionsPerPage"
           :total-rows="sessionsTotal"
           first-number
           last-number
           prev-class="prev-item"
           next-class="next-item"
           class="float-right"
       >
         <template #prev-text>
           <feather-icon
               icon="ChevronLeftIcon"
               size="18"
           />
         </template>
         <template #next-text>
           <feather-icon
               icon="ChevronRightIcon"
               size="18"
           />
         </template>
       </b-pagination>
     </b-col>

     <student-add-to-session v-on:refresh="refreshSessions" v-if="studentData" :is-add-session-open.sync="isAddSessionOpen" :student-data="studentData" />
   </b-row> -->
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BRow,
  BButton,
  BCol,
  BBadge,
  BPagination,
} from 'bootstrap-vue';
import { avatarText, title } from '@core/utils/filter';
import useStudentsList from '@/views/apps/student/students-list/useStudentsList';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import arStoreModule from '@/views/apps/accounting/receivable/receivableStoreModule';
import router from '@/router';
import moment from 'moment';
import StudentAddToSession from '../components/StudentAddToSession';

export default {
  components: {
    StudentAddToSession,

    BCard,
    BCardBody,
    BLink,
    BDropdown,
    BDropdownItem,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BPagination,
  },
  props: {
    studentId: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      isAddSessionOpen: false,
    };
  },
  methods: {
    openSessionSelect() {
      this.isAddSessionOpen = true;
    },

    async removeFromSession(session) {
      const { id } = session;

      await this.$store
        .dispatch('app-ar/removeStudentsFromSession', {
          sessionId: id,
          profileIds: this.profileIds,
        })
        .then(() => this.refreshSessions())
        .catch((error) => {
          console.error(`failed to remove students to class`, error);
        });
    },
    firstClass(session) {
      if (!session.courses) {
        return undefined;
      }

      const courses = session.courses;

      const classes = courses.map((course) => course.classes).flat();

      let sorted = classes.sort((a, b) => a.date - b.date);

      return sorted[0];
    },
  },
  mounted() {
    this.fetchStudent();
    this.refreshSessions();

    if (this.$route.query.op === 'ar_cohort.assign') {
      this.isAddSessionOpen = true;
    }
  },
  setup(props) {
    const profileIds = ref([]);
    const studentData = ref(null);
    const sessionData = ref({});
    const sessionsPage = ref(1);
    const sessionsPerPage = ref(10);
    const sessionsTotal = ref(1);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';
    const AR_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);

    if (!store.hasModule(AR_APP_STORE_MODULE_NAME)) store.registerModule(AR_APP_STORE_MODULE_NAME, arStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);

      if (store.hasModule(AR_APP_STORE_MODULE_NAME)) store.unregisterModule(AR_APP_STORE_MODULE_NAME);
    });

    const fetchStudent = () => {
      store
        .dispatch('app-student/fetchStudent', {
          id: props.studentId,
        })
        .then((response) => {
          studentData.value = response.data;
        });
    };

    const accountsData = ref([]);

    const refreshSessions = () => {
      store
        .dispatch('app-accounting-receivable/fetchAccounts', {
          student: props.studentId,
          populate: 'cohort',
        })
        .then((response) => {
          const { data } = response;

          accountsData.value = data.results;

          console.log(`found accounts`, data);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            sessionData.value = undefined;
          }
        });
    };

    const resolveSessionStatusVariant = (status) => {
      if (status === 'created') return 'secondary';
      if (status === 'enrolling') return 'primary';
      if (status === 'waiting') return 'warning';
      if (status === 'live') return 'info';
      if (status === 'completed') return 'success';
      return 'primary';
    };

    return {
      sessionData,
      avatarText,
      title,
      moment,
      resolveSessionStatusVariant,

      refreshSessions,

      fetchStudent,
      studentData,

      profileIds,
      sessionsPage,
      sessionsPerPage,
      sessionsTotal,
    };
  },
};
</script>
