<template>
  <b-card>
    <b-card-body class="p-0 w-100">
      <b-tabs content-class="mt-2" justified pills lazy>
        <b-tab title="Sessions">
          <p class="text-center">
            <student-relations-session-list v-if="studentData && studentData.id" :student-id="studentData.id" />
          </p>
        </b-tab>
        <b-tab title="Courses">
          <p class="text-center">
            <student-relations-course-list v-if="studentData && studentData.id" :student-id="studentData.id" />
          </p>
        </b-tab>
        <b-tab title="Transcripts">
          <p class="text-center">This student has no associated transcripts.</p>
        </b-tab>
        <b-tab title="(AR) Accounts">
          <p class="text-center">
            <student-relations-ar-accounts-list v-if="studentData && studentData.id" :student-id="studentData.id" />
          </p>
        </b-tab>
        <b-tab title="Enrollments">
          <p class="text-center">This student has no associated enrollments.</p>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BTab, BTabs } from 'bootstrap-vue';
import { barePermissionsData, initialAbility, permissionsData } from '@/libs/acl/config';
import { title } from '@core/utils/filter';
import StudentRelationsSessionList from '@/views/apps/student/students-view/relations/StudentRelationsSessionList';
import StudentRelationsCourseList from '@/views/apps/student/students-view/relations/StudentRelationsCourseList';
import StudentRelationsArAccountsList from '@/views/apps/student/students-view/relations/StudentRelationsArAccountsList';

export default {
  components: {
    StudentRelationsCourseList,
    StudentRelationsArAccountsList,
    StudentRelationsSessionList,
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const applyAbility = (permissions, abilities) => {
      let modules = { ...permissions };

      for (let ability of abilities) {
        if (ability.subject === 'system') continue;
        if (!modules[ability.subject])
          modules[ability.subject] = { read: false, write: false, create: false, delete: false };
        modules[ability.subject][ability.action] = true;
      }

      return Object.keys(modules).map((moduleName) => {
        return { module: moduleName, ...modules[moduleName] };
      });
    };

    let abilities = [...initialAbility];

    if (props.studentData.ability) {
      abilities.push(...props.studentData.ability);
    }

    let pmd = applyAbility({}, abilities);

    return {
      permissionsData: pmd,
      title,
    };
  },
};
</script>

<style></style>
