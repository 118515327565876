<template>
  <b-modal
    id="modal-lg"
    ok-title="Blacklist"
    centered
    size="lg"
    title="Add the student to the blacklist"
    ref="modal"
    @hidden="() => $emit('update:is-student-blacklist-open', false)"
    :visible="isStudentBlacklistOpen"
    @ok="submitBlacklist"
  >
    <b-card no-body>
      <b-row>
        <b-col cols="12" md="3" lg="3">
          <b-form-group label="Position" label-for="blacklist-rank">
            <b-form-input required id="blacklist-rank" placeholder="3" v-model="blData.rank" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12" lg="12">
          <b-form-group label="Reason" label-for="blacklist-reason">
            <b-form-textarea required id="blacklist-reason" v-model="blData.reason" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store/index';
import { onUnmounted, ref } from '@vue/composition-api';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import moment from 'moment';
import { title } from '@core/utils/filter';

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BFormTextarea,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
  },
  data() {
    return {
      isBusy: false,
      searchTerm: '',
      perPage: 10,
      currentPage: 0,
      totalResults: 0,

      profileFields: [
        { key: 'studentId', sortable: true },
        { key: 'fullName', sortable: true },
        { key: 'email', sortable: true },
        { key: 'phoneNumber', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],

      stage: 'profile',
      profile: undefined,

      sortBy: 'createdAt',
      sortDesc: true,

      blData: { rank: 0 },
    };
  },
  props: {
    isStudentBlacklistOpen: {
      type: Boolean,
    },
    studentData: {
      type: Object,
    },
    profileData: {
      type: Object,
    },
  },
  methods: {
    profileProvider(ctx) {
      const promise = store.dispatch('app-student/fetchStudents', {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy ? ctx.sortBy : 'createdAt'}:${ctx.sortDesc ? 'desc' : 'asc'}`,
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.totalResults = totalResults;

          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching session list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },
    submitBlacklist() {
      this.$swal({
        title: 'Are you sure?',
        text: 'This action will not have any permanent consequences but may in the future.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, blacklist!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('app-student/updateStudentProfile', {
              id: this.studentData.id,
              profileData: {
                blacklist: {
                  enabled: true,
                  timestamp: new Date(),
                  reason: this.blData.reason,
                  rank: this.blData.rank,
                },
              },
            })
            .then(() => {
              this.$emit('update:is-student-blacklist-open', false);
              setTimeout(() => location.reload(), 1500);

              this.$swal({
                icon: 'success',
                title: 'Blacklisted!',
                text: 'This student has been added to the blacklist',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            });
        }
      });
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    },
  },

  setup() {
    const sessionData = ref(null);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';
    const ROSTER_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    if (!store.hasModule(ROSTER_APP_STORE_MODULE_NAME))
      store.registerModule(ROSTER_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
      if (store.hasModule(ROSTER_APP_STORE_MODULE_NAME)) store.unregisterModule(ROSTER_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();

    return { toast, title, moment };
  },
};
</script>
