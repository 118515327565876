<template>
  <b-card no-body class="m-0">
    <b-card-body class="m-0 pt-0 pb-0">
      <p class="text-center" v-if="!documentData || documentData.length === 0">
        This student has no associated {{ category }} documents.
      </p>

      <b-row v-else>
        <!-- Swiper -->
        <swiper class="swiper-responsive-breakpoints py-2 w-100" :options="swiperOptions">
          <swiper-slide v-for="(document, index) in documentData" :key="index" :alt="document.name">
            <b-link v-b-tooltip.hover.bottom="document.name" :alt="document.name">
              <div class="item-heading w-100">
                <h5 class="text-truncate mb-0">
                  <b-badge v-if="document.tfv && document.tfv.completed" variant="success" pill>P</b-badge>
                  <b-badge variant="dark" pill>{{ nameToExtension(document.name) }}</b-badge>
                  {{ document.name }}
                </h5>
                <small class="text-body">
                  <b-badge variant="primary" size="sm">{{ document.subCategory }}</b-badge>
                  {{ moment(document.createdAt).format('L') }}
                </small>
              </div>
              <div class="img-container w-100 mx-auto py-75">
                <b-img
                  style="height: 100%; width: 100%; box-shadow: 4px 4px 3px 0px #ea5455"
                  class="border border-dark"
                  :src="document.thumbnailUrl"
                />

                <b-button-group variant="outline-primary" size="sm" block class="align-bottom mt-1 w-100">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    :disabled="disableDownload"
                    @click="() => initializeDownload(document.id, document.name, true)"
                  >
                    Preview
                  </b-button>

                  <b-dropdown dropup v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="outline-primary">
                    <b-dropdown-item
                      :disabled="disableDownload"
                      @click="() => initializeDownload(document.id, document.name)"
                    >
                      Download
                    </b-dropdown-item>
                    <b-dropdown-item v-if="documentHasProof(document)" @click="downloadProof(document)">Voice</b-dropdown-item>
                    <b-dropdown-item v-if="false" disabled>Replace</b-dropdown-item>
                    <b-dropdown-divider/>
                    <b-dropdown-item
                        @click="() => deleteDocument(document.id)">
                      Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </b-button-group>
              </div>
            </b-link>
          </swiper-slide>

          <!-- Add Arrows -->
          <div slot="button-next" class="swiper-button-next" />
          <div slot="button-prev" class="swiper-button-prev" />
        </swiper>
      </b-row>
    </b-card-body>

    <b-button
      variant="primary"
      size="sm"
      block
      class="w-100"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-upload-enrollment
    >
      Click here to upload
    </b-button>

    <b-modal
      id="modal-upload-enrollment"
      ref="modal-upload-enrollment"
      title="Select document type"
      cancel-variant="outline-secondary"
      no-stacking
      @ok="onSubCategorySelect"
    >
      <b-form class="m-1">
        <b-form-group label="Choose the type of document you would like to upload." label-for="select-upload-enrollment">
          <v-select
            id="select-upload-enrollment"
            v-model="upload.type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="selectUploadEnrollmentOptions[category]"
            :selectable="(option) => !option.value.includes('select_value')"
            :clearable="false"
            @input="
              (val) => {
                upload.type = val;
                onSubCategorySelect();
              }
            "
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-upload-document"
      ref="modal-upload-document"
      title="Select local file"
      cancel-variant="outline-secondary"
      @ok="openUploadEnroll"
    >
      <b-form class="m-1">
        <b-form-group
          label="Choose the name of the document you will attach. Leave empty to default to the name of the file from your computer."
          label-for="select-upload-name"
        >
          <b-form-input
            id="select-upload-name"
            v-model="upload.fileName"
            :placeholder="`${title(upload.type.label)} #1.pdf`"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Choose the document you would like to upload and attach to this student."
          label-for="select-upload-document"
        >
          <b-form-file
            id="select-upload-document-file"
            accept=".jpg, .jpeg, .png, .pdf, .doc"
            :capture="true"
            :autofocus="true"
            @input="onFileSelect"
            v-model="upload.file"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BImg,
  BLink,
  BButton,
  BRow,
  BBadge,
  BTooltip,
  VBTooltipPlugin,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormFile,
  BFormGroup,
} from 'bootstrap-vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import moment from 'moment';
import { title } from '@/@core/utils/filter';
import store from '@/store';

import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import { onUnmounted, ref } from '@vue/composition-api';
import router from '@/router';
import fileDownload from 'js-file-download';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BImg,
    BLink,
    BButton,
    BRow,
    vSelect,
    Ripple,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormFile,
    BFormGroup,
    BBadge,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    // 3rd Party
    Swiper,
    SwiperSlide,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltipPlugin,
    Ripple,
  },
  props: {
    studentId: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      upload: {
        type: { value: 'select_value', label: 'Select Type' },
        fileName: undefined,
        file: undefined,
      },
      disableDownload: false,
      selectUploadEnrollmentOptions: {
        enrollment: [
          { value: 'select_value', label: 'Select Type' },
          { value: 'coe', label: 'Certificate of Eligibility' },
          { value: 'tac', label: 'Terms and Conditions' },
          { value: 'ef', label: 'Enrollment Form' },
          { value: 'resume', label: 'Resume' },
        ],
        financial: [
          { value: 'select_value', label: 'Select Type' },
          { value: 'invoice', label: 'Invoice' },
          { value: 'receipt', label: 'Receipt' },
          { value: 'quote', label: 'Quote' },
        ],
        placement: [
          { value: 'select_value', label: 'Select Type' },
          { value: 'resume', label: 'Resume' },
          { value: 'me', label: 'Meaningful Employment' },
          { value: 'jol', label: 'Job Offer Letter' },
          { value: 'coc', label: 'Certificate of Completion' },
          { value: 'esr', label: 'Exam Score Report' },
        ],
        transcript: [
          {value: 'select_value', label: 'Select Type'},
          {value: 'transcript', label: 'Transcript'},
          {value: 'toc', label: 'Transfer of Credits'},
        ],
        misc: [
          {value: 'select_value', label: 'Select Type'},
          {value: 'misc', label: 'Misc'},
          {value: 'evidence', label: 'Evidence'},
        ]
      },
    };
  },
  methods: {
    nameToExtension(name) {
      if (!name) return name;
      const spl = name.split('.');
      return spl[spl.length - 1];
    },
    async deleteDocument(documentId) {
      const documentResp = await store
        .dispatch('app-student/deleteStudentDocument', {
          studentId: this.studentId,
          documentId,
        })
        .then(() => this.refetchData())
        .catch((error) => console.log(`Failed to delete document`, error));
    },
    async onSubCategorySelect(mdl) {
      if (mdl) {
        mdl.preventDefault();
      }

      this.$refs['modal-upload-document'].show();
    },

    async openUploadEnroll() {
      if (!this.upload.type || !this.upload.type.value || this.upload.type.value === 'select_value') {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to create document.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Failed to select or invalid subcategory.',
          },
        });
      }

      const formData = new FormData();
      formData.append('document', this.upload.file);

      try {
        const documentResp = await store
          .dispatch('app-student/createStudentDocument', {
            studentId: this.studentId,
            payload: {
              name: this.upload.fileName || this.upload.file.name,
              category: this.category,
              subCategory: this.upload.type.value,
            },
          })
          .catch((error) => console.log(`Failed to create document`, error));

        if (documentResp.status !== 201) {
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: documentResp.data.message || 'An unknown error has occurred.',
            },
          });
        }

        const document = documentResp.data;

        const uploadResp = await store
          .dispatch('app-student/uploadStudentDocument', {
            studentId: this.studentId,
            documentId: document.id,
            formData,
          })
          .catch((error) => console.log(`Failed to upload student document`, error));

        if (uploadResp.status !== 200) {
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: uploadResp.data.message || 'An unknown error has occurred.',
            },
          });
        }

        this.refetchData();
      } catch (e) {
        if (e.response) {
          const { response } = e;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create and upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: response.data.message || 'An unknown error has occurred.',
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create and upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: e.message,
            },
          });
        }
      }
    },

    onFileSelect() {
      console.log(this.upload.file);

      if (!this.upload.fileName) {
        this.upload.fileName = this.upload.file.name;
      }
    },
    documentHasProof(document) {
      return document.tfv && document.tfv.proofUrl;
    },
    async downloadProof(document) {
      if (document.tfv && document.tfv.proofUrl) {
        window.open(document.tfv.proofUrl, '_blank');
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to download',
            icon: 'SettingsIcon',
            variant: 'danger',
            text: 'Document does not have a recording attached',
          },
        });
      }
    },
    async initializeDownload(documentId, documentName, preview = false) {
      this.disableDownload = true;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please wait a moment...',
          icon: 'SettingsIcon',
          variant: 'success',
          text: 'Your download is being initialized.',
        },
      });

      await store
        .dispatch('app-student/downloadStudentDocument', {
          studentId: this.studentId,
          documentId,
        })
        .then((response) => {
          const { data, headers } = response;

          if (preview && headers['content-type'] === 'application/pdf') {
            const blobUrl = URL.createObjectURL(data);

            const blobWindow = window.open(
              blobUrl,
              'blank',
              'popup, left=50, top=50, scrollbars=yes, width=1200, height=800, location=no'
            );
            blobWindow.focus();
          } else {
            fileDownload(data, documentName);
          }

          this.disableDownload = false;
        })
        .catch((error) => {
          console.log(`Failed to download student document`, error);
          this.disableDownload = false;

          if (error.response) {
            const { response } = error;
            return this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to download file.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: response.data.message || 'An unknown error has occurred.',
              },
            });
          }

          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to download file.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: e.message,
            },
          });
        });
    },
  },
  setup(props) {
    const swiperOptions = {
      slidesPerView: 3,
      spaceBetween: 55,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1600: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    };

    const documentData = ref(null);
    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    const refetchData = () => {
      store
        .dispatch('app-student/fetchStudentDocuments', {
          id: router.currentRoute.params.id,
          queryParams: {
            category: props.category,
            limit: 50,
            sortBy: 'createdAt:desc',
          },
        })
        .then((response) => {
          documentData.value = response.data.results;
          console.log(`documents`, response.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            documentData.value = undefined;
          }
        });
    };

    refetchData();

    return {
      swiperOptions,
      documentData,
      moment,
      title,
      refetchData,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

.swiper-slide {
  background: none !important;
}
</style>
