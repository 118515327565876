<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="studentData === undefined">
      <h4 class="alert-heading">Error fetching student data</h4>
      <div class="alert-body">
        No student found with this student id. Check
        <b-link class="alert-link" :to="{ name: 'apps-students-list' }"> Student List </b-link>
        for other students.
      </div>
    </b-alert>

    <template v-if="studentData">
      <b-row>
        <b-col cols="12" lg="6">
          <student-view-student-info-card :student-data="studentData" :profile-data="profileData" />

          <student-view-student-plan-card :student-data="studentData" />
        </b-col>

        <b-col cols="12" lg="6">
          <student-view-student-permissions-card :student-data="studentData" />

          <student-view-student-timeline-card :student-data="studentData" :profile-data="profileData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue';
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue';
import studentStoreModule from '../studentStoreModule';
import StudentViewStudentInfoCard from './StudentViewStudentInfoCard.vue';
import StudentViewStudentPlanCard from './StudentViewStudentPlanCard.vue';
import StudentViewStudentTimelineCard from './StudentViewStudentTimelineCard.vue';
import StudentViewStudentPermissionsCard from './StudentViewStudentPermissionsCard.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    StudentViewStudentInfoCard,
    StudentViewStudentPlanCard,
    StudentViewStudentTimelineCard,
    StudentViewStudentPermissionsCard,

    InvoiceList,
  },
  setup() {
    const studentData = ref({});
    const profileData = ref({});

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch('app-student/fetchStudent', { id: router.currentRoute.params.id })
      .then((response) => {
        studentData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          studentData.value = undefined;
        }
      });

    store
      .dispatch("app-student/fetchStudentProfile", {
        id: router.currentRoute.params.id,
        queryParams: { populate: "notes.author" }
      })
      .then((response) => {
        profileData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          profileData.value = undefined;
        }
      });

    return {
      studentData,
      profileData,
    };
  },
};
</script>

<style></style>
