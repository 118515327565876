<template>
  <b-card>
    <b-card-body class="p-0 w-100">
      <b-tabs content-class="mt-2" pills lazy>
        <b-tab title="Notes">
          <student-note-item v-if="profileData && profileData.notes" :notes="profileData.notes" />

          <b-button
            class="mt-1"
            variant="primary"
            size="sm"
            :to="{ name: 'apps-students-edit', params: { id: studentData.id }, query: { tab: 'notes' } }"
          >
            Edit Notes
          </b-button>
        </b-tab>
        <b-tab title="Social">
          <student-social-item v-if="profileData && profileData.socialLinks" :social-links="profileData.socialLinks" />

          <b-button
            class="mt-1"
            variant="primary"
            size="sm"
            :to="{ name: 'apps-students-edit', params: { id: studentData.id }, query: { tab: 'social' } }"
          >
            Edit Links
          </b-button>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCol, BCard, BImg, BMedia, BAvatar, BCardBody, BTabs, BTab, BButton } from 'bootstrap-vue';
import StudentNoteItem from './components/StudentNoteItem';
import StudentSocialItem from './components/StudentSocialItem';

export default {
  components: {
    BCol,
    BCard,
    BCardBody,
    BTabs,
    BTab,
    BImg,
    BMedia,
    BAvatar,
    BButton,
    StudentNoteItem,
    StudentSocialItem,
  },
  props: {
    studentData: {
      type: Object,
    },
    profileData: {
      type: Object,
    },
  },
};
</script>

<style></style>
