<template>
  <b-card>
    <b-tabs justified pills>
      <b-tab title="Enrollment" class="text-center" lazy>
        <student-document-list :studentId="studentData.id" category="enrollment" />
      </b-tab>
      <b-tab title="Financial" class="text-center" lazy>
        <student-document-list :studentId="studentData.id" category="financial" />
      </b-tab>
      <b-tab title="Placement" class="text-center" lazy>
        <student-document-list :studentId="studentData.id" category="placement" />
      </b-tab>
      <b-tab title="Transcript" class="text-center" lazy>
        <student-document-list :studentId="studentData.id" category="transcript" />
      </b-tab>
      <b-tab title="Miscellaneous" class="text-center" lazy>
        <student-document-list :studentId="studentData.id" category="misc" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BTab,
  BTabs,
  BRow,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormFile,
  BFormGroup,
} from 'bootstrap-vue';
import { barePermissionsData, initialAbility, permissionsData } from '@/libs/acl/config';
import { title } from '@core/utils/filter';

import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import StudentDocumentList from '@/views/apps/student/students-view/documents/StudentDocumentList';

export default {
  components: {
    StudentDocumentList,
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BRow,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormFile,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      upload: {
        type: { value: undefined, label: 'Select Type' },
        file: undefined,
      },
      selectUploadEnrollmentOptions: [
        { value: 'select_value', label: 'Select Type' },
        { value: 'coe', label: 'Certificate of Eligibility' },
        { value: 'resume', label: 'Resume' },
      ],
    };
  },
  methods: {
    openUploadEnroll() {},
  },

  setup(props) {
    const applyAbility = (permissions, abilities) => {
      let modules = { ...permissions };

      for (let ability of abilities) {
        if (ability.subject === 'system') continue;
        if (!modules[ability.subject])
          modules[ability.subject] = {
            read: false,
            write: false,
            create: false,
            delete: false,
          };
        modules[ability.subject][ability.action] = true;
      }

      return Object.keys(modules).map((moduleName) => {
        return { module: moduleName, ...modules[moduleName] };
      });
    };

    let abilities = [...initialAbility];

    if (props.studentData.ability) {
      abilities.push(...props.studentData.ability);
    }

    let pmd = applyAbility({}, abilities);

    return {
      permissionsData: pmd,
      title,
    };
  },
};
</script>

<style></style>
