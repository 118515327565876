<template>
  <b-card>
    <b-row>
      <!-- Student Info: Left col -->
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <!-- Student Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="studentData.avatar"
            :text="avatarText(studentData.fullName)"
            :variant="`light-${resolveStudentRoleVariant(studentData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0" @click="copyElementToClipboard('fullName')">
                {{ studentData.fullName }}
              </h4>
              <span class="card-text" @click="copyElementToClipboard('email')">{{ studentData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-dropdown variant="primary" block text="Actions">
                <b-dropdown-item :to="{ name: 'apps-students-edit', params: { id: studentData.id } }">Edit </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('student.blacklist.remove') && profileData.blacklist && profileData.blacklist.enabled"
                  @click="startStudentUnBlacklist"
                  >Un-Blacklist
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('student.blacklist.add') && ((profileData.blacklist && !profileData.blacklist.enabled) || !profileData.blacklist)"
                  @click="startStudentBlacklist"
                  >Blackilst</b-dropdown-item
                >

                <b-dropdown-item @click="startStudentMerge">Merge</b-dropdown-item>
                <b-dropdown-item v-if="false" href="#">Archive</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="HashIcon" class="mr-75" />
              <span class="font-weight-bold">Student Id</span>
            </th>
            <td class="pb-50" @click="copyElementToClipboard('studentId')">
              {{ studentData.studentId }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ studentData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td class="pb-50 text-capitalize" @click="copyElementToClipboard('phoneNumber')">
              {{ studentData.phoneNumber }}
            </td>
          </tr>
          <tr v-if="profileData.blacklist && profileData.blacklist.enabled">
            <th class="pb-50">
              <font-awesome-icon class="mr-75" icon="fa-solid fa-poo"></font-awesome-icon>
              <span class="font-weight-bold">Blacklisted</span>
            </th>
            <td class="pb-50 text-capitalize">since {{ moment(profileData.blacklist.timestamp).utc().format('L') }}</td>
          </tr>
        </table>
      </b-col>

      <b-col v-if="profileData.blacklist && profileData.blacklist.enabled" md="12" lg="12">
        <hr />

        <p>
          ⚠️ <i>Blacklisted for the following reason:<br /></i>
          {{ profileData.blacklist.reason || 'No reason specified' }}
        </p>
      </b-col>
    </b-row>

    <student-blacklist
      @refresh="$emit('refresh')"
      :is-student-blacklist-open.sync="isStudentBlacklistOpen"
      :student-data.sync="studentData"
      :profile-data.sync="profileData"
    ></student-blacklist>
    <student-merger :is-student-merger-open.sync="isStudentMergerOpen" :student-data.sync="studentData"></student-merger>
  </b-card>
</template>

<script>
import { BAvatar, BButton, BCard, BCol, BDropdown, BDropdownItem, BDropdownItemButton, BRow } from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import useStudentsList from '../students-list/useStudentsList';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import StudentMerger from '@/views/apps/student/students-view/components/StudentMerger';
import moment from 'moment';
import StudentBlacklist from './components/StudentBlacklist';

export default {
  components: {
    StudentMerger,
    StudentBlacklist,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BDropdownItem,
    BDropdownItemButton,
    BDropdown,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    profileData: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      isStudentBlacklistOpen: false,
      isStudentMergerOpen: false,
    };
  },
  methods: {
    startStudentBlacklist() {
      this.isStudentBlacklistOpen = true;
    },
    startStudentUnBlacklist() {
      this.$store
        .dispatch('app-student/updateStudentProfile', {
          id: this.studentData.id,
          profileData: {
            blacklist: { enabled: false },
          },
        })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Un-Blacklisted!',
            text: 'This student has been removed to the blacklist',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        });
    },
    copyElementToClipboard(id) {
      this.$copyText(this.studentData[id]).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Copied ${id} to clipboard!`,
              icon: 'BellIcon',
            },
          });
        },
        (e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Could not copy!',
              icon: 'BellIcon',
            },
          });
        }
      );
    },
    startStudentMerge() {
      this.isStudentMergerOpen = true;
    },
  },
  setup() {
    const { resolveStudentRoleVariant } = useStudentsList();
    return {
      avatarText,
      resolveStudentRoleVariant,
      //
      moment,
    };
  },
};
</script>

<style></style>
