<template>
  <b-row>
    <b-table :items="notes" :fields="['vss', 'title', 'description', 'createdAt']">
      <template #cell(vss)="data">
        <b-avatar
          size="32"
          :src="data.item.author.avatar"
          :text="avatarText(data.item.author.name)"
          variant="primary"
          :to="{ name: 'apps-users-view', params: { id: data.item.author.id } }"
          v-b-tooltip.hover
          :title="data.item.author.name"
        />
      </template>
      <template #cell(createdAt)="data">
        {{ data.value ? moment(data.value).format('L') : 'unknown' }}
      </template>
      <template #cell(description)="data">
        <span @click="applyCol(data.value)">
          {{ data.value && data.value.length > 120 && !expanded.includes(data.value._id) ? data.value.substring(0, 120) + '...' : data.value }}
        </span>
      </template>
    </b-table>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BTab,
  BTabs,
  BCol,
  BLink,
  BBadge,
  BRow,
  BAvatar,
  BMedia,
} from 'bootstrap-vue';
import moment from 'moment';
import { avatarText } from '@core/utils/filter';

export default {
  name: 'ProfileNoteItem',
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BCol,
    BLink,
    BBadge,
    BRow,
    BAvatar,
    BMedia,
  },
  props: {
    notes: {
      type: Array,
    },
  },
  data: () => {
    return {
      expanded: []
    }
  },
  methods: {
    applyCol(val) {
      if (!val) return

      if (this.expanded.includes(val._id)) {
        this.expanded.splice(this.expanded.indexOf(val._id), 1)
      } else {
        this.expanded.push(val._id)
      }
    }
  },
  setup(props) {
    return {
      moment,
      avatarText
    };
  },
};
</script>

<style lang="scss" scoped>
.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
</style>
