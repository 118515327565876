<template>
  <b-row>
    <b-col lg="12">
      <p class="text-center" v-if="sessionData.length === 0">This student is not enrolled in any sessions.</p>
    </b-col>

    <b-col cols="12" lg="6" md="6" sm="12" v-for="(profile, index) in profiles" :key="profile.id">
      <router-link :to="{ name: 'apps-roster-session-view', params: { id: profile.session.id } }">
        <b-card no-body class="border border-dark">
          <b-card-body class="d-flex w-100 pb-0" style="padding: 7px 7px 0px 7px">
            <b-avatar :text="avatarText(profile.session.name)" :variant="`light-primary`" size="45px" class="mr-1"></b-avatar>

            <b-row class="w-100 text-left" style="padding-left:5px;">
              <div style="width:80%!important;">
                <h5 class="font-weight-normal " >{{ profile.session.name }}</h5>
              </div>
              <div style="width:20%!important;">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body mx-0" />
                  </template>

                  <b-dropdown-item :to="{ name: 'apps-roster-profile-edit', params: { id: profile.id } }">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="$can('roster.session.student.remove')" @click="removeFromSession(profile.session, profile)">
                    <feather-icon icon="ArchiveIcon" />
                    <span class="align-middle ml-50">Remove</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-row>
          </b-card-body>

          <div style="padding: 0px 7px 7px 7px">
            <hr class="p-0" style="margin: 0 0 7px;">

            <div class="float-left mr-1">
              <b-badge :variant="resolveSessionStatusVariant(profile.session.status)">
                {{ title(profile.session.status) }}
              </b-badge>
              <b-badge class="ml-1" variant="secondary">
                {{ title(profile.status) }}
              </b-badge>
            </div>

            <div class="float-right" v-if="profile.session.startDate">
              {{ moment(profile.session.startDate).utc().format("L") }}
              <component is="span" v-if="profile.session.endDate">
                -
                {{ moment(profile.session.endDate).utc().format("L") }}
              </component>
            </div>
            <div class="float-right" v-else> No Class Scheduled</div>
          </div>


        </b-card>
      </router-link>
    </b-col>

    <b-col cols="12" md="12" class="w-100">
      <b-button @click="openSessionSelect()" class="float-left" variant="primary" size="sm"> Add to Session </b-button>

      <b-pagination
        v-model="sessionsPage"
        :per-page="sessionsPerPage"
        :total-rows="sessionsTotal"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="float-right"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>

    <student-add-to-session
      v-on:refresh="refreshSessions"
      v-if="studentData"
      :is-add-session-open.sync="isAddSessionOpen"
      :student-data="studentData"
    />
  </b-row>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BRow,
  BButton,
  BCol,
  BBadge,
  BPagination,
} from 'bootstrap-vue';
import { avatarText, title } from '@core/utils/filter';
import useStudentsList from '@/views/apps/student/students-list/useStudentsList';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import moment from 'moment';
import StudentAddToSession from '../components/StudentAddToSession';

export default {
  components: {
    StudentAddToSession,

    BCard,
    BCardBody,
    BLink,
    BDropdown,
    BDropdownItem,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BPagination,
  },
  props: {
    studentId: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      isAddSessionOpen: false,
    };
  },
  methods: {
    openSessionSelect() {
      this.isAddSessionOpen = true;
    },

    async removeFromSession(session, profile) {
      const { id } = session;

      await this.$store
        .dispatch('app-roster/removeStudentsFromSession', {
          sessionId: id,
          profileIds: [ profile.id ],
        })
        .then(() => this.refreshSessions())
        .catch((error) => {
          console.error(`failed to remove students to class`, error);
        });
    },
    firstClass(session) {
      if (!session.courses) {
        return undefined;
      }

      const courses = session.courses;

      const classes = courses.map((course) => course.classes).flat();

      let sorted = classes.sort((a, b) => a.date - b.date);

      return sorted[0];
    },
  },
  mounted() {
    this.fetchStudent();
    this.refreshSessions();

    if (this.$route.query.op === 'session.assign') {
      this.isAddSessionOpen = true;
    }
  },
  setup(props) {
    const _profiles = ref([]);
    const profileIds = ref([]);
    const studentData = ref(null);
    const sessionData = ref({});
    const sessionsPage = ref(1);
    const sessionsPerPage = ref(10);
    const sessionsTotal = ref(1);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';
    const ROSTER_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);

    if (!store.hasModule(ROSTER_APP_STORE_MODULE_NAME))
      store.registerModule(ROSTER_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);

      if (store.hasModule(ROSTER_APP_STORE_MODULE_NAME)) store.unregisterModule(ROSTER_APP_STORE_MODULE_NAME);
    });

    const fetchStudent = () => {
      store
        .dispatch('app-student/fetchStudent', {
          id: props.studentId,
        })
        .then((response) => {
          studentData.value = response.data;
        });
    };

    const refreshSessions = () => {
      store
        .dispatch('app-student/fetchStudentSessions', {
          id: props.studentId,
          queryParams: {
            limit: sessionsPerPage.value,
            page: sessionsPage.value,
            populate: 'courses,courses.classes,students',
            sortBy: '_id:desc',
          },
        })
        .then((response) => {
          const { data } = response;

          profileIds.value = data.profileIds;

          _profiles.value = data.profiles.filter(prof => prof.session);

          sessionData.value = data.sessions.results;
          sessionsTotal.value = data.sessions.totalResults;
          console.log(`session`, data);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            sessionData.value = undefined;
          }
        });
    };

    const resolveSessionStatusVariant = (status) => {
      if (status === 'created') return 'secondary';
      if (status === 'enrolling') return 'primary';
      if (status === 'waiting') return 'warning';
      if (status === 'live') return 'info';
      if (status === 'completed') return 'success';
      return 'primary';
    };

    return {
      sessionData,
      avatarText,
      title,
      moment,
      resolveSessionStatusVariant,

      refreshSessions,

      fetchStudent,
      studentData,

      profileIds,
      profiles: _profiles,
      sessionsPage,
      sessionsPerPage,
      sessionsTotal
    };
  },
};
</script>
