<template>
  <b-modal
    id="modal-lg"
    ok-title="Overwrite Profile"
    centered
    size="xl"
    title="Search for mergers"
    ref="modal"
    @hidden="() => $emit('update:is-student-merger-open', false)"
    v-model="isStudentMergerOpen"
    :hide-footer="true"
  >
    <b-card no-body>
      <b-row>
        <b-col cols="12" lg="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalResults"
            :per-page="perPage"
            aria-controls="table-test"
          ></b-pagination>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group class="justify-content-end">
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="handleSearch"
              />
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        id="table-profiles"
        :busy.sync="isBusy"
        :items="profileProvider"
        :fields="profileFields"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchTerm"
      >
        <template #cell(status)="data">
          {{ title(data.item.status) }}
        </template>

        <template #cell(actions)="data">
          <b-button @click="selectProfile(data.item)" variant="primary" size="sm">Select</b-button>
        </template>
      </b-table>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store/index';
import { onUnmounted, ref } from '@vue/composition-api';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import moment from 'moment';
import { title } from '@core/utils/filter';

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
  },
  data() {
    return {
      isBusy: false,
      searchTerm: '',
      perPage: 10,
      currentPage: 0,
      totalResults: 0,

      profileFields: [
        { key: 'studentId', sortable: true },
        { key: 'fullName', sortable: true },
        { key: 'email', sortable: true },
        { key: 'phoneNumber', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],

      stage: 'profile',
      profile: undefined,

      sortBy: 'createdAt',
      sortDesc: true,
    };
  },
  props: {
    isStudentMergerOpen: {
      type: Boolean,
    },
    studentData: {
      type: Object,
    },
  },
  methods: {
    profileProvider(ctx) {
      const promise = store.dispatch('app-student/fetchStudents', {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy ? ctx.sortBy : 'createdAt'}:${ctx.sortDesc ? 'desc' : 'asc'}`,
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.totalResults = totalResults;

          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching session list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },
    selectProfile(selectedStudent) {
      this.$swal({
        title: 'Are you sure?',
        text: 'This will overwrite the selected profile with the one you are currently viewing.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, replace!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('app-student/mergeStudents', {
              targetId: this.studentData.id,
              originId: selectedStudent.id,
            })
            .then(() => {
              this.$emit('update:is-student-merger-open', false);
              setTimeout(() => location.reload(), 1500);

              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'The selected profile has been overwritten and deleted!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            });
        }
      });
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    },
  },

  setup() {
    const sessionData = ref(null);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';
    const ROSTER_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    if (!store.hasModule(ROSTER_APP_STORE_MODULE_NAME))
      store.registerModule(ROSTER_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
      if (store.hasModule(ROSTER_APP_STORE_MODULE_NAME)) store.unregisterModule(ROSTER_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();

    return { toast, title, moment };
  },
};
</script>
