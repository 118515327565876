<template>
  <b-row>
    <b-table :items="socialLinks" :fields="['label', 'url']">
      <template #cell(url)="data">
        <a target="_blank" :href="data.item.url">
          {{ data.item.url }}
          <feather-icon icon="ExternalLinkIcon" />
        </a>
      </template>
    </b-table>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BTab,
  BTabs,
  BCol,
  BLink,
  BBadge,
  BRow,
} from 'bootstrap-vue';
import moment from 'moment';

export default {
  name: 'StudentSocialItem',
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BCol,
    BLink,
    BBadge,
    BRow,
  },
  props: {
    socialLinks: {
      type: Array,
    },
  },
  setup(props) {
    return {
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
</style>
